
@import '../assets/css/variables.css';

.page-aab {
	--paragraph-inset: 1.25em;

	& > * + *:not(.comp-footer) {
		margin-top: var(--spacer-padding);
	}

	.single-aab {
		counter-increment: listNumbering;
		max-width: calc(100vmin - var(--spacer) - var(--spacer));
		margin-inline: auto;

		h6 {
			display: inline-block;
			margin-top: 0;
			margin-right: 0;
			// optically align to counters
			margin-left: var(--paragraph-inset);
			padding: 0;
		}

		ul {

			& > li {
				list-style: none;
				margin-left: calc(var(--border-radius)*-2);
			}

			& > span {
				margin-left: calc(var(--border-radius)*2);
				display: inline-block;
			}

		}

		ol {
			margin: 0 0 0 0;
			padding: 0;
			counter-reset: paragraphNumbering;


			& > * + * {
				margin-top: .5em;
			}

			& > li {
				list-style: none;
				position: relative;

				& > span {
					margin-left: var(--paragraph-inset);
					display: inline-block;
				}

				&:before {
					font-variant-numeric: tabular-nums;

					display: inline-block;
					position: absolute;

					left: calc(var(--border-radius)*2);
					transform: translateX(-100%);

					counter-increment: paragraphNumbering;
					content: counters(listNumbering, ".", decimal-leading-zero) "." counters(paragraphNumbering, ".") "  ";
					content: counters(listNumbering, ".") "." counters(paragraphNumbering, ".") "  ";
					text-align: right;
				}
			}
		}

		&:nth-of-type(10) ol {
			margin: 0 0 0 calc(var(--border-radius) * .5);
		}
	}
}
